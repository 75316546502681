<template>
  <AboutUsPage/>
</template>

<script>
import AboutUsPage from '@innerworks_ds/innerworks-storybook-main/src/stories/AbousUsPage/AboutUsPage.vue';

export default {
  name: 'AboutUs',
  components: {
    AboutUsPage,
  },
  data: () => ({
  }),
};
</script>

<style lang="scss" scoped>
</style>
